import React from 'react';
import { Flex, Text, Spacer } from '@chakra-ui/react';
import { textStyleProps } from './styles';
import Social from '@components/Shared/Social';
import { PHONE_NUMBER } from '@src/commons/constants';
import socialLinks from '@components/Shared/Social/socialLinks';
import SectionWrapper from '@components/Shared/SectionWrapper';
import SectionHeader from '@components/Shared/SectionHeader';
import { colorsGrey } from '@src/theme/chakraTheme/colors';
import FadeInWhenVisible from '@components/Shared/FadeInWhenVisible/FadeInWhenVisible';
import Email from '@components/Shared/Email';
import SubscribeForm from '@components/Shared/SubscribeForm';

const ContactMe: React.FC = () => {
  return (
    <SectionWrapper order={3} py={12} bgColor="#f5f5f5">
      <Flex grow={1} direction="column">
        <SectionHeader normalText="Contact" boldText="Me" />
        <FadeInWhenVisible>
          <Flex
            mt={['10px', null, '50px']}
            w="100%"
            direction={['column', null, 'row']}
          >
            <Flex
              direction="column"
              mb={[8, null, 0]}
              align={['center', null, 'flex-start']}
            >
              <Email />
              <Text
                color={colorsGrey[300]}
                fontFamily="montserrat"
                fontWeight="medium"
                fontSize="lg"
              >
                {PHONE_NUMBER}
              </Text>
              <Text {...textStyleProps}>Write, text or call me for any</Text>
              <Text {...textStyleProps} mb="23px">
                suggestion, project or question
              </Text>
              <Social
                socialLinks={socialLinks}
                mb={0}
                mt={['9px', null, '58px']}
              />
            </Flex>
            <Spacer />
            <Flex direction="column" grow={1} align="center">
              <Text
                color="primary"
                fontFamily="montserrat"
                fontStyle="bold"
                fontSize="2xl"
                lineHeight={7}
                mb={4}
              >
                Subscribe to my newsletter
              </Text>
              <SubscribeForm
                formInputProps={{
                  borderColor: colorsGrey[100],
                  _hover: { borderColor: colorsGrey[100] },
                }}
              />
            </Flex>
          </Flex>
        </FadeInWhenVisible>
      </Flex>
    </SectionWrapper>
  );
};

export default ContactMe;
