import { BoxProps, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { EMAIL } from '@src/commons/constants';

const Email: React.FC<BoxProps> = (props) => {
  return (
    <Heading as="h4" fontSize="2xl" {...props}>
      <Text
        as="a"
        fontFamily="montserrat"
        fontWeight="bold"
        color="primary"
        _hover={{ color: 'secondary' }}
      >
        {EMAIL}
      </Text>
    </Heading>
  );
};

export default Email;
