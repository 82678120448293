import styled, { css } from 'styled-components';

const InputStyle = css`
  border: 1px solid ${({ theme }) => theme.colors.white} !important;
  border-radius: 0px !important;
  color: ${({ theme }) => theme.colors.grey.light};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 300;

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    transition: all 320ms ease-in-out;
  }
  &:focus {
    color: ${({ theme }) => theme.colors.grey.light};
    box-shadow: none !important;
    border: 1px solid ${({ theme }) => theme.colors.primary} !important;
  }
`;

export const TextArea = styled.textarea`
  resize: none !important;
  ${InputStyle}
`;

export const Input = styled.input`
  ${InputStyle}
`;

export const SubmitButton = styled.button`
  color: ${({ theme }) => theme.colors.white} !important;
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 600;
  outline: none !important;
  padding: 8px 16px;
  background-color: ${({ theme }) => theme.colors.primary};
  border: 1px solid ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme.colors.primary} !important;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background-color: ${({ theme }) => theme.colors.white} !important;
  }

  ${({ theme }) => theme.BreakpointSmallMobile`
    margin-bottom: 30px;
  `}
`;

export const Error = styled.p`
  color: #c17272;
  margin-top: 10px;
`;

export const CheckboxWrapper = styled.div`
  .chakra-checkbox__control[data-checked],
  .chakra-checkbox__control[data-checked][data-hover] {
    /* background-color: ${({ theme }) => theme.colors.secondary}; */
    background: ${({ theme }) => theme.colors.secondary};
    border-color: ${({ theme }) => theme.colors.secondary};
  }
`;
