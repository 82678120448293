import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { ISectionWrapperProps } from './interfaces';

const SectionWrapper: FCWithChildren<ISectionWrapperProps> = ({
  children,
  order = 1,
  ...rest
}) => {
  return (
    <Box as="section" order={order} {...rest}>
      <Flex
        direction={['column', null, 'row']}
        maxW={1180}
        px={5}
        justify="center"
        margin="0 auto"
      >
        {children}
      </Flex>
    </Box>
  );
};

export default SectionWrapper;
