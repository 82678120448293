import styled from 'styled-components';
import { BoxProps } from '@chakra-ui/react';
import { colorsGrey } from '@src/theme/chakraTheme/colors';
import fonts from '@src/theme/chakraTheme/fonts';

export const Section = styled.section`
  background-color: ${({ theme }) => theme.colors.grey.catskillWhite};
  padding: 3rem 0 !important;
`;

export const ContactHeader = styled.h1`
  padding-right: 30px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 0px 40px 40px 0px;
  background-color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  padding-left: 9%;
`;

export const Light = styled.span`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 300;
  color: ${({ theme }) => theme.colors.grey.light};
`;

export const Bold = styled.span`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

export const PhoneNumber = styled.h5`
  color: ${({ theme }) => theme.colors.grey.light};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 500;
`;

export const EmailLink = styled.a`
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Address = styled.p`
  color: ${({ theme }) => theme.colors.grey.light};
  font-family: ${({ theme }) => theme.fonts.montserrat};
  font-weight: 600;
  letter-spacing: 1.3px;
`;

export const textStyleProps: BoxProps = {
  color: colorsGrey[300],
  fontFamily: fonts.fonts.montserrat,
  fontWeight: 300,
  fontSize: 'lg',
  lineHeight: 1.7,
  mb: 0,
};
