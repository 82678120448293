import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { IContactMeFormMessageProps } from './interfaces';

const ContactFormMessage: React.FC<IContactMeFormMessageProps> = ({
  title,
  description,
}) => {
  return (
    <Box bgColor="secondary" color="white" p={4} rounded="md">
      <Text fontFamily="montserrat" fontWeight="bold" mb={0}>
        {title}
      </Text>
      <Text mb={0}>{description}</Text>
    </Box>
  );
};

export default ContactFormMessage;
