import axios, { AxiosResponse } from 'axios';

export const subscribeToList = async (
  firstName: string,
  lastName: string,
  email: string
): Promise<AxiosResponse<any>> => {
  const data = {
    firstName,
    lastName,
    email,
  };

  try {
    const res = await axios({
      method: 'post',
      url: '/api/newsletterSubscribe',
      headers: {
        'Content-Type': 'application/json',
      },
      data,
    });
    return res;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
    return Promise.reject(error);
  }
};
