import React from 'react';
import { FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { IFormInputProps } from './interfaces';
import { colorsGrey } from '@src/theme/chakraTheme/colors';

const FormInput: FCWithChildren<IFormInputProps> = ({
  fieldError,
  type = 'text',
  placeholder,
  formControlProps,
  formInputProps,
  children,
  register,
}) => {
  return (
    <FormControl
      isInvalid={!!fieldError}
      transition="all 0.3s ease"
      {...formControlProps}
    >
      {children || (
        <Input
          fontFamily="montserrat"
          fontWeight="light"
          type={type}
          placeholder={placeholder}
          color={colorsGrey[300]}
          borderColor="white"
          borderRadius="0px"
          outline="none"
          _focusVisible={{ outline: 'none' }}
          _placeholder={{ color: colorsGrey[200] }}
          _hover={{ borderColor: 'none' }}
          {...formInputProps}
          {...register}
        />
      )}
      <FormErrorMessage>{fieldError?.message}</FormErrorMessage>
    </FormControl>
  );
};

export default FormInput;
