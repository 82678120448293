/* eslint-disable react/display-name */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Flex,
  Spacer,
  Button,
  useToast,
  Checkbox,
  FormErrorMessage,
  FormControl,
} from '@chakra-ui/react';
import FormInput from '../FormInput';
import { IFormInputs, ISubscribeFormProps } from './interfaces';
import SubscribeFormMessage from './SubscribeFormMessage';
import useSubscribeFormValidation from './utils/useSubscribeFormValidation';
import { CheckboxWrapper } from './styles';
import { Loader } from '@components/Shared/Loader';
import { colorsGrey } from '@src/theme/chakraTheme/colors';
import { subscribeToList } from '@src/lib/networking/subscribe-api';

const SubscribeForm: React.FC<ISubscribeFormProps> = ({
  formInputProps = {},
  formControlProps = {},
  buttonProps = {},
}) => {
  const validationSchema = useSubscribeFormValidation();
  const toast = useToast();
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(validationSchema),
  });
  const [loading, setLoading] = useState(false);

  const onSubmit = async ({ firstName, lastName, email }: IFormInputs) => {
    setLoading(true);
    const { status } = await subscribeToList(firstName, lastName, email);
    setLoading(false);
    if (status < 300) {
      toast({
        status: 'success',
        duration: 2000,
        position: 'bottom',
        render: () => (
          <SubscribeFormMessage
            title="Thank you"
            description="For signing up for the newsletter"
          />
        ),
      });
      reset();
    } else
      toast({
        status: 'error',
        duration: 2000,
        position: 'bottom',
        render: () => (
          <SubscribeFormMessage
            title="Error"
            description="An error has occured"
          />
        ),
      });
  };

  if (loading) return <Loader />;

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      direction="column"
    >
      <Flex mb={4}>
        <FormInput
          register={register('firstName')}
          placeholder="First Name"
          fieldError={errors.firstName}
          formControlProps={{ w: '47%', ...formControlProps }}
          formInputProps={formInputProps}
        />
        <Spacer />
        <FormInput
          register={register('lastName')}
          placeholder="Last Name"
          fieldError={errors.lastName}
          formControlProps={{ w: '47%', ...formControlProps }}
          formInputProps={formInputProps}
        />
      </Flex>
      <FormInput
        register={register('email')}
        placeholder="Email"
        fieldError={errors.email}
        formControlProps={{ w: 'full', ...formControlProps }}
        formInputProps={formInputProps}
      />
      <FormControl isInvalid={!!errors.approve}>
        <CheckboxWrapper>
          <Checkbox
            fontSize="lg"
            lineHeight={1.7}
            fontFamily="montserrat"
            fontWeight="light"
            color="grey.300"
            borderColor={colorsGrey[200]}
            mt={4}
            mb={6}
            {...register('approve')}
          >
            Please add me to the mailing list
          </Checkbox>
        </CheckboxWrapper>
        <FormErrorMessage mb={2} mt={0}>
          {errors.approve?.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        alignSelf={['center', null, 'flex-start']}
        type="submit"
        text="submit"
        color="white"
        bgColor="primary"
        fontFamily="montserrat"
        fontWeight="semibold"
        outline="none"
        py={2}
        px={4}
        border="1px"
        borderStyle="solid"
        borderColor="primary"
        borderRadius="0px"
        transition="all 0.3s ease"
        _hover={{
          color: 'primary',
          bgColor: 'white',
        }}
        {...buttonProps}
      >
        SIGN UP
      </Button>
    </Flex>
  );
};

export default SubscribeForm;
