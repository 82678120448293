import React, { FC } from 'react';
import ReactLoading from 'react-loading';
import { IProps, LoaderTypes } from './interfaces';
import { LoaderContainer } from './styles';
import theme from '@src/theme';

const Loader: FC<IProps> = ({
  type = LoaderTypes.Spin,
  color = theme.colors.primary,
  height = 100,
  width = 100,
  containerStyle,
}) => {
  return (
    <LoaderContainer style={containerStyle || {}}>
      <ReactLoading type={type} color={color} height={height} width={width} />
    </LoaderContainer>
  );
};

export default Loader;
