export interface IProps {
  type?: LoaderTypes;
  color?: string;
  delay?: number;
  height?: string | number;
  width?: string | number;
  className?: string;
  containerStyle?: React.CSSProperties;
}

// eslint-disable-next-line no-shadow
export enum LoaderTypes {
  Spin = 'spin',
  Bars = 'bars',
  Bubbles = 'bubbles',
  SpinningBubbles = 'spinningBubbles',
}
